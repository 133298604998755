<div class="container-fluid">
  <div class="row">
    <div class="col-auto p-0 bg-primary text-white" [(ngbCollapse)]="sidebarCollapsed" [horizontal]="true">
      <div class="collapse collapse-horizontal show">
        <div id="sidebar-nav" class="list-group">
          <div class="sticky-top px-3 pt-2 bg-primary ">
            <div class="d-inline-block me-2" style="top: -10px; position: relative;">
              <img src="../../../assets/images/logo-small.png" height="45">
            </div>
            <div class="d-inline-block">
              <h6 class="fw-semibold my-1"> Mastercup Online (V.2)</h6>
              <div class="text-white" style="font-size: 0.8rem;">National Health Security Office</div>
            </div>
          </div>
          <ng-container [ngTemplateOutlet]="sidebar"></ng-container>
        </div>
      </div>
    </div>
    <main class="col p-0">
      <nav id="top-nav" class="navbar sticky-top">
        <div class="bg-pattern"></div>
        <div class="container-fluid">
          <ng-container *ngIf="!toggleOffCanvas">
            <button (click)="sidebarCollapsed = !sidebarCollapsed" [attr.aria-expanded]="!sidebarCollapsed"
                    class="navbar-toggler border-0 p-0" type="button"
                    data-bs-toggle="collapse" data-bs-target="#sidebar" aria-controls="sidebar" aria-expanded="false"
                    aria-label="Toggle navigation">
              <i class="bi bi-layout-sidebar text-white fs-3"></i>
            </button>
          </ng-container>
          <ng-container *ngIf="toggleOffCanvas">
            <ng-template #content let-offcanvas>
              <div class="offcanvas-header">
                <h6 class="offcanvas-title" id="offcanvas-basic-title">เมนู</h6>
                <button type="button" class="btn-close" aria-label="Close"
                        (click)="offcanvas.dismiss('Cross click')"></button>
              </div>
              <div class="offcanvas-body">
                <ng-container [ngTemplateOutlet]="sidebar"></ng-container>
              </div>
            </ng-template>
            <button (click)="open(content)" [attr.aria-expanded]="!sidebarCollapsed" class="navbar-toggler border-white"
                    type="button" data-bs-toggle="collapse"
                    data-bs-target="#sidebar" aria-controls="sidebar" aria-expanded="false"
                    aria-label="Toggle navigation">
              <i class="bi bi-layout-sidebar text-white fs-3"></i>
            </button>
          </ng-container>
          <div *ngIf="!user && !isAuthenticated" class="d-flex">
            <a class="btn btn-sm btn-default border-0" (click)="login()" role="button">ลงชื่อเข้าใช้</a>
          </div>
          <div *ngIf="user && isAuthenticated" class="d-flex">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 ms-3 text-truncate text-end" style="min-width: 150px;">
                <div class="align-items-center">
                  <a role="button" class="btn btn-sm text-white" id="dropdownMenu"
                     style="text-align: start; padding: 0;">
                    {{ user?.name }}
                  </a>
                </div>
                <div class="text-white"
                     style="font-size: 0.8rem;">
                  {{ organizationName(user?.organization) }}
                </div>
              </div>
            </div>
            <div ngbDropdown class="flex-shrink-0 mx-3 text-black-50">
              <a role="button" class="text-white" ngbDropdownToggle>
                <i class="bi-person-circle fs-3 "></i>
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdownMenu">
                <button ngbDropdownItem (click)="userInfo()">
                  <i class="bi bi-person me-3"></i>บัญชีผู้ใช้งาน
                </button>
                <button ngbDropdownItem (click)="logout()">
                  <i class="bi bi-door-open me-3"></i>ออกจากระบบ
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div id="main-content" class="container-fluid pt-3">
        <app-version></app-version>
        <app-header></app-header>
        <app-network-unstable></app-network-unstable>
        <app-loading></app-loading>
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
</div>
<div class="container-fluid" style="background-color: rgb(248 249 250);">
  <footer class="d-flex flex-wrap justify-content-between align-items-center py-3">
    <p class="col-md-4 mb-0 text-body-secondary">© 2023 สำนักงานหลักประกันสุขภาพแห่งชาติ</p>

    <a href="/"
       class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
      <svg class="bi me-2" width="40" height="32">
        <use xlink:href="#bootstrap"></use>
      </svg>
    </a>

    <div class="col-md-4 justify-content-end text-end">
      <span>เวอร์ชั่น {{ version?.build?.version }}</span>
    </div>
  </footer>
</div>
<ng-template #sidebar>
  <ng-container>
    <ul id="sidebar-menu" class="list-group pt-3" style="list-style: none; padding: 0;">
      <li>
        <a routerLink="/landing" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncatex"
           data-bs-parent="#sidebar">
          <img src="../../../assets/icons/home.png" alt="" class="me-2" height="15">หน้าแรก
        </a>
      </li>
      <li>
        <a routerLink="/search" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncatex"
           data-bs-parent="#sidebar">
          <img src="../../../assets/icons/search.png" alt="" class="me-2" height="15">ค้นหาเครือข่ายหน่วยบริการ
        </a>
      </li>
      <li *hasAnyAuthority="['nhso', 'zone']">
        <a routerLink="/inbox" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncatex"
           data-bs-parent="#sidebar">
          <img src="../../../assets/icons/calendar-clock.png" alt="" class="me-2" height="15">
          <div>Inbox</div>
        </a>
      </li>
      <div class="submenu1" *hasAnyAuthority="['nhso', 'zone']">
        <li>
          <a routerLink="/inbox/add" routerLinkActive="active"
             class="list-group-item border-0 d-inline-block text-truncatex"
             data-bs-parent="#sidebar">
            <img src="../../../assets/icons/folder-add.png" alt="" class="me-2" height="15">
            <div>
              จัดเครือข่ายหน่วยบริการใหม่
              <span class="badge badge rounded-pill bg-danger">{{ inboxService?.countHra }}</span>
            </div>
          </a>
        </li>
        <ng-container *ngIf="environment.hospitalNetworkModule">
          <li>
            <a routerLink="/hospital-network/cancel" routerLinkActive="active"
               class="list-group-item border-0 d-inline-block text-truncatex"
               data-bs-parent="#sidebar">
              <img src="../../../assets/icons/folder-remove.png" alt="" class="me-2" height="15">
              <div>
                ยกเลิกเครือข่ายหน่วยบริการ
                <!--              <span class="badge badge rounded-pill bg-danger">8</span>-->
              </div>
            </a>
          </li>
          <li>
            <a routerLink="/hospital-network/edit" routerLinkActive="active"
               class="list-group-item border-0 d-inline-block text-truncatex"
               data-bs-parent="#sidebar">
              <img src="../../../assets/icons/folder-edit.png" alt="" class="me-2" height="15">
              <div>
                แก้ไขสถานะเครือข่ายหน่วยบริการ
                <!--              <span class="badge badge rounded-pill bg-danger">74</span>-->
              </div>
            </a>
          </li>
        </ng-container>
      </div>

      <li *hasAnyAuthority="['nhso', 'zone']">
        <a routerLink="/hospital-transfer-import" routerLinkActive="active" *hasAnyAuthority="['nhso']"
           class="list-group-item border-0 d-inline-block text-truncatex"
           data-bs-parent="#sidebar">
          <img src="../../../assets/icons/folder-add.png" alt="" class="me-2" height="15">
          <div>นำเข้า file หน่วยบริการแม่ข่าย</div>
        </a>
        <a routerLink="/hospital-transfer-manager" routerLinkActive="active" *hasAnyAuthority="['nhso']"
           class="list-group-item border-0 d-inline-block text-truncatex"
           data-bs-parent="#sidebar">
          <img src="../../../assets/icons/folder-add.png" alt="" class="me-2" height="15">
          <div>จัดการหน่วยบริการแม่ข่าย</div>
        </a>
        <a routerLink="/province-transfer" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncatex"
           data-bs-parent="#sidebar">
          <img src="../../../assets/icons/hospital.png" alt="" class="me-2" height="15">
          <div>เพิ่มข้อมูลรพ.สต. ถ่ายโอน รายจังหวัด</div>
        </a>
        <a routerLink="/hospital-transfer" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncatex"
           data-bs-parent="#sidebar">
          <img src="../../../assets/icons/hospital.png" alt="" class="me-2" height="15">
          <div>ข้อมูลรพ.สต. ถ่ายโอน</div>
        </a>
      </li>
      <li *hasAnyAuthority="['nhso','zone']">
        <a routerLink="/potential" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncatex"
           data-bs-parent="#sidebar">
          <img src="../../../assets/icons/globe.png" alt="" class="me-2" height="15">กำหนดจำนวนโควต้าผู้ลงทะเบียน
        </a>
      </li>
      <li *hasAnyAuthority="['nhso']">
        <a routerLink="/email-management" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncatex"
           data-bs-parent="#sidebar">
          <img src="../../../assets/icons/send-main.png" alt="" class="me-2" height="15">จัดการอีเมลผู้ใช้งาน
        </a>
      </li>
      <li *hasAnyAuthority="['nhso', 'zone']">
        <a routerLink="/export-text-file" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncatex"
           data-bs-parent="#sidebar">
          <img src="../../../assets/icons/file-text.png" alt="" class="me-2" height="15">ส่งออกข้อมูล text ไฟล์
        </a>
      </li>
      <li>
        <a routerLink="/report" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncatex"
           data-bs-parent="#sidebar">
          <img src="../../../assets/icons/pie-chart.png" alt="" class="me-2" height="15">รายงาน
        </a>
      </li>
    </ul>
  </ng-container>
</ng-template>
