import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from "./layout/layout.component";
import {FrontChannelLogoutComponent} from "./shared/front-channel-logout/front-channel-logout.component";
import {LandingComponent} from "./pages/landing/landing.component";
import {P403Component} from "./shared/commons/interceptor/error/403.component";
import {AutoLoginPartialRoutesGuard} from "angular-auth-oidc-client";
import {ProvinceTransferComponent} from "./pages/province-transfer/province-transfer.component";
import {
  HospitalTransferYearImportComponent
} from "./pages/hospital-transfer/import/hospital-transfer-year-import.component";
import {ManagerComponent} from "./pages/hospital-transfer/manager/manager.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: "full",
    redirectTo: "landing"
  },
  {
    path: '',
    pathMatch: 'prefix',
    component: LayoutComponent,
    children: [
      {
        path: 'landing',
        component: LandingComponent
      },
      {
        path: 'landing-auth',
        component: LandingComponent,
        canActivate: [AutoLoginPartialRoutesGuard],
      },
      {
        path: 'search',
        loadChildren: () => import('./pages/search/search.module').then(value => value.SearchModule)
      },
      {
        path: 'catm-near',
        loadChildren: () => import('./pages/catm-near/catm-near.module').then(value => value.CatmNearModule)
      },
      {
        path: 'hospital-network',
        loadChildren: () => import('./pages/hospital-network/hospital-network.module').then(value => value.HospitalNetworkModule)
      },
      {
        path: 'hrefer-noncap',
        loadChildren: () => import('./pages/hrefer-noncap/hrefer-noncap.module').then(value => value.HreferNoncapModule)
      },
      {
        path: 'potential',
        loadChildren: () => import('./pages/potential/potential.module').then(value => value.PotentialModule)
      },
      {
        path: 'email-management',
        loadChildren: () => import('./pages/email-management/email-management.module').then(value => value.EmailManagementModule)
      },
      {
        path: 'report',
        loadChildren: () => import('./pages/report/report.module').then(value => value.ReportModule)
      },
      {
        path: 'inbox',
        loadChildren: () => import('./pages/inbox/inbox.module').then(value => value.InboxModule)
      },{
        path: 'export-text-file',
        loadChildren: () => import('./pages/export-text-file/export-text-file.module').then(value => value.ExportTextFileModule)
      },
      {
        path: 'hospital-transfer',
        canActivate: [AutoLoginPartialRoutesGuard],
        loadChildren: () => import('./pages/hospital-transfer/hospital-transfer.module').then(value => value.HospitalTransferModule)
      },
      {
        path: 'hospital-transfer-import',
        canActivate: [AutoLoginPartialRoutesGuard],
        data: {title: 'นำเข้า file หน่วยบริการแม่ข่าย'},
        component : HospitalTransferYearImportComponent
      },
      {
        path: 'hospital-transfer-manager',
        canActivate: [AutoLoginPartialRoutesGuard],
        data: {title: 'จัดการหน่วยบริการแม่ข่าย'},
        component : ManagerComponent
      },
      {
        path: 'province-transfer',
        canActivate: [AutoLoginPartialRoutesGuard],
        component: ProvinceTransferComponent
      },
      {
        path: '403',
        component: P403Component,
      },
    ]
  },
  {
    path: 'logout',
    component: FrontChannelLogoutComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    urlUpdateStrategy: "eager",
    paramsInheritanceStrategy: "always",
    bindToComponentInputs: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
